import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"

import SEO from "../components/seo"
import {Row, Col, Card, Divider, Button, Alert, Tooltip} from "antd";
import {utils} from "../services/utils";

const HolesPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Buche | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-prizes">
                <div className="container text-center pt-4">
                    <h1>Le buche</h1>
                    <h5 className="text-uppercase text-primary fw-bold">Fai click su un logo per scoprirne di più!</h5>
                </div>
                <section className="pt-4">
                    <div className="container">
                        <Row gutter={24} type="flex">
                            {utils.sponsors.map(sponsor => {
                                return (
                                    <Col xs={24} sm={12} lg={8} xl={6}>
                                        <Card
                                            bordered
                                            className="shadow text-center mb-4">
                                            <h5 className="text-uppercase fw-bold text-primary">Buca {sponsor.order}</h5>
                                            <h6 className="fw-bold mb-0">Sponsored by</h6>
                                            <Link to={sponsor.url}>
                                                <Tooltip title="Fai click per scoprire di più">
                                                    <img src={sponsor.logo} alt={sponsor.name} className="prize-logo"/>
                                                </Tooltip>
                                            </Link>
                                            <img alt="Wuerth Open" className="hole-img" src={sponsor.hole}/>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default HolesPage
